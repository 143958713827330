import { useRef, useState, useEffect, Fragment } from 'react';
import { Button, Container } from 'react-bootstrap'

import emailjs from '@emailjs/browser'
import {USER_ID, SERVICE_ID, TEMPLATE_ID } from "../keys/emailkey";

const Contact = () => {
  const form = useRef();

  const [messageSent, setMessageSent] = useState(false);
  const [messageSentError, setMessageSentError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isRobot, setIsRobot] = useState(true);

  const [isValid, setIsValid] = useState(false);
  
  useEffect(() => {
    setIsValid(
      name.length >= 1 &&
      isEmailValid(email) &&
      subject.length >= 1 &&
      message.length >= 1 &&
      !isRobot );
  }, [name, email, subject, message, isRobot])

  const isEmailValid = (email) => {
    // const emailRegexp = new RegExp(
    //   /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
    // )
    const emailRegexp = new RegExp(
      /^[a-zA-Z0-9][-_.+!#$%&'*/=?^`{|]{0,1}([a-zA-Z0-9][-_.+!#$%&'*/=?^`{|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-.]{0,1}([a-zA-Z][-.]{0,1})*[a-zA-Z0-9].[a-zA-Z0-9]{1,}([.-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
    )
    return emailRegexp.test(email)
  }

  const handleSubmit = (e) => {
    
    if (isValid) {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
        .then((result) => {
          setMessageSent(true);
          setMessageSentError(false);
        }, (error) => {
          setMessageSentError(true);
        });
    }
  };

  const handleClear = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setIsRobot(true);
    setMessageSent(false);
  }

  return (
    <Fragment>
      <Container className="py-md-5 py-sm-3 px-4">
        <div className="d-inline-block text-center">
          <h1 className='pt-5'>Contact</h1>
          <div className='subtitle pt-2 pb-md-5 pb-3'>Do you want to hear more about ethical design, ask about my thesis, offer me some work, or perhaps just drop me a message? You can do all that on this page.</div>
        </div>
      </Container>
      <Container fluid className='py-5 gray-bg px-4'>
        <Container>
          <h2 className='py-2 pb-5 text-center'>Send me a message</h2>
          <form ref={form}>
            <div className="pb-2 form-group row">
              <label className="col-sm-2 col-form-label">Name</label>
              <div className='col-sm-10'>
                <input
                className='form-control'
                name="from_name"
                placeholder='Jane Doe'
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={messageSent}
                />
              </div>
            </div>
            <div className="pb-2 form-group row">
              <label className="col-sm-2 col-form-label">Email</label>
              <div className='col-sm-10'>
                <input
                className='form-control'
                name="reply_to"
                placeholder='jane.doe@email.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={messageSent}
                />
              </div>
            </div>
            <div className="pb-2 form-group row">
              <label className="col-sm-2 col-form-label">Subject</label>
              <div className='col-sm-10'>
                <input
                className='form-control'
                name="subject"
                placeholder='Subject'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                disabled={messageSent}
                />
              </div>
            </div>
            <div className="pb-2 form-group row">
              <label className="col-sm-2 col-form-label">Message</label>
              <div className='col-sm-10'>
                <textarea
                rows="5"
                className='form-control'
                name="message"
                placeholder='Type your message here'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={messageSent}
                />
              </div>
            </div>
            <div className="pb-5 form-group row">
              <label className="col-sm-2 col-form-label">Are you a robot?</label>
              <div className="py-2 col-auto">
                <div className='form-check'>
                  <input
                  className='form-check-input'
                  type="radio"
                  value="isRobot"
                  name="isRobotRadio"
                  disabled={messageSent}
                  onClick={() => setIsRobot(true)}
                  id="radio1" />
                  <label className="form-check-label">
                    Beep boop, I am a robot
                  </label>
                </div>
              </div>
              <div className="py-2 col-auto">
                <div className='form-check'>
                  <input
                  className='form-check-input'
                  type="radio"
                  value="isNotRobot"
                  name="isRobotRadio"
                  disabled={messageSent}
                  onClick={() => setIsRobot(false)}
                  id="radio2" />
                  <label className="form-check-label">
                    No, I don't think so
                  </label>
                </div>
              </div>
            </div>
            
            <div className="form-group row justify-content-between">
              <div className="col-auto">
                <Button variant='dark' className="proto-button mb-2" onClick={handleClear}>Clear</Button>
              </div>
              <div className="col-auto">
                <Button variant='dark' className="proto-button mb-2" onClick={handleSubmit} disabled={!isValid || messageSent}>Send</Button>
              </div>
            </div>
          </form>
          {messageSent &&
          <div className='pt-4 text-center'>
            Message received! I'll reply shortly :)
          </div>}
          {messageSentError &&
          <div className='pt-4 text-center'>
            Huh, something unexpected happened and your message was not sent :(
          </div>}
        </Container>
      </Container>
      <div className='py-5'></div>
      {/* <Container className='py-4'>
        <h2 className='pt-md-5 py-3'>Socials & Whatnot</h2>
        <p className='d-inline-block col-8'>If online forms and email feels too clumsy and old-fashioned, don't hesitate to reach out to me on LinkedIn. Feel free to even drop a DM on my artist page on Instagram, Twitter or Mastodon.</p>
        <p className='d-inline-block col-4'>Yelp</p>
        
      </Container> */}
    </Fragment>
  )
}

export default Contact