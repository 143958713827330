import { useEffect, useState } from 'react'

import { Nav, Image } from "react-bootstrap"
import { Link } from 'react-router-dom'

const DARK_LIMIT = 128 + 64;

const WorkCard = ({title, src, id}) => {
  const [isMd, setIsMd] = useState(window.screen.width >= 768);
  const [isDark, setIsDark] = useState(false);

  const handleResize = () => setIsMd(window.screen.width >= 768);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    setMode()
  })

  const setMode = async () => {
    await delay(1);
    var el = document.getElementById(`work-${id}`);
    var rgb = getAverageRgb(el);
    var overlay = document.getElementById(`work-${id}-ol`);
    overlay.style.backgroundColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    setIsDark(Math.floor((rgb.r + rgb.g + rgb.b) / 3) < DARK_LIMIT);
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const scrollToTop = () => window.scrollTo({top: 0, behavior: 'instant'});

  const getAverageRgb = (imgEl) => {
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);

    return rgb;
  }

  return(
    <div className="d-inline-block col-lg-4 col-md-6 col-12 work-card">
      <Nav as={Link} to={`/work/${id}`} onClick={scrollToTop}>
        <Image id={`work-${id}`} src={src} style={{width: "100%"}} />
        <div id={`work-${id}-ol`} className={`overlay${!isMd ? " mobile" : ""}`}>
          <h4 className={`col-10 text${isDark ? " dark" : ""}${!isMd ? " bold" : ""}`}>{title}</h4>
        </div>
      </Nav>
    </div>
  )
}

export default WorkCard