import thumbnail from "../../assets/mastersThesis/thumbnail.png"
import header from "../../assets/mastersThesis/header.png"
import ethicalDesign from "../../assets/mastersThesis/ethicalDesign.png"
import diary from "../../assets/mastersThesis/diary.png"

import MyImg from "../../components/myImg"

const Thesis = {
  title: "Master's Thesis: Perceived Benefits and Drawbacks of Ethical Design",
  subtitle: "Master's Thesis in Human-Technology Interaction at Tampere University",
  thumbnailImg: thumbnail, // this shows on home page
  headerImg: header, // this is the big banner image on project page
  link: {
    title: "Read Full Thesis",
    url: "https://urn.fi/URN:NBN:fi:tuni-2023111510000"
  },
  id: "thesis", // simplified name
  overview: {
    title: "Thesis Overview",
    challenge: <span><p>There is a widespread need for ethical design in today's world. So, this thesis investigates the perceived benefits and drawbacks as experienced by ethical design practitioners, aiming to render the effects of ethical design more tangible and comprehensible to a broader audience. Moreover, the concrete evidence of ethical design could act as an argument business-wise.</p><p>A number of benefits were found and categorised along with their downside counterparts. The tone in which the professionals discuss about the benefits points strongly to the notion that they are worth the extra effort, in comparison to non-ethical design. Furthermore, the results will also open the possibility of discussions among designers, stakeholders, customers, and end-users about the benefits and downsides of ethical design.</p><p>The research implicates ethical design has more benefits than downsides. The responsibility of delivering ethically-driven products and services needs to be shared to be able to harness the full potential of the benefits that comes with ethical design. The sooner it is adopted the better; ethical design has more positive impacts than negative ones which makes it a cost-effective investment both for individual designers and companies.</p></span>,
    objectivesTitle: "Research Questions",
    objectives: [
      "What kind of benefits are believed to have arisen from ethical design and to whom?",
      "What are the perceived drawbacks or trade-offs of explicitly ethical design?",
      <strike>What are the most effective strategies for incorporating ethical design into UX design?</strike>
    ],
    scope: "Research, Ethical Design",
    role: "Researcher",
    team: "Self-directed, self-motivated",
    tools: "Zoom, FigJam, MS Word",
    duration: "11 months",
  },
  processName: "Research Process",
  sections: [
    { 
      name: "Background",
      dispName: "Background Research",
      content: [
        [
          <p>The background research was initiated by taking a deep-dive into the (non-) existing literature about Ethical Design and case studies where it was attempted to be defined. It quickly became apparent there are not any agreed-upon definitions and all the terms surrounding the topic carry no clear definitions either. For the purposes of the research, I defined it as a layer on top of Human-Technology Interaction and grouped several things under it so it's more graspable. This is shown in the figure in the vicinity of this paragraph.</p>,
          <p>UX design has a strong tradition of trying to support business either with direct effects – a good experience increases sales – or indirect effects, such as brand value. This explains why royalty-of-investment and cost-justified usability are so associated with UX. At the same time, there is a strong mindset that UX designers are on the side of the users and sheltering them from malicious technology corporates. That is, interesting conflicts of values and different beliefs. This creates an interesting tension to research.</p>,
          <p>Ethical design has taken its first steps and gained foothold in the design community. There are several different manifestos, guides, toolkits, and frameworks for ethical design available on the internet but the consensus about the definition is still lacking. Although ethical pondering is generally recognised as important, it inevitably incurs
          costs and at the same time the benefits are vague. Ethical examination is basically considering the different dimensions of good and evil and identifying the contradictions and
          compromises related to them. However, it is not all black and white; things can also be neutral. This goodness-badness spectrum can be and is being used in design. Ethical Design, descendant of Value Sensitive Design and Human-Technology Interaction, emerges as a promising design paradigm and interesting topic of research.</p>
        ],
        [
          <MyImg alt="Ethical Design depicted as a layer on top of Human-Technology Interaction adding value thinking to the existing design paradigm" src={ethicalDesign} modal />
        ]
      ],
    },
    {
      name: "Material & Methods",
      dispName: "Material & Methods",
      content: [
        [
          <p> Four participants, proficient with ethical design, with different backgrounds and motivation were recruited. These
          participants consisted of professional designers, both UX designers and service designers, working in companies that describe ethics being part of their design process on their public websites. Furthermore, the interviewees were ideally working in different projects to maximise the diversity and avoid biasing the data.</p>,
          <p>Each of the study participants was interviewed at the beginning, given a longitudinal diary assignment, and interviewed again at the end of the research process. The time span for studying the participants was not static; to assure the viability of the amount and quality of the data, the diary study commenced until the researcher decided enough data has been accumulated. The study phases were conducted parallel to data analysis. This allowed to better enhance the focus of the subsequent phase based on the themes found in
          the previous one.</p>,
          <p>It was evident from the day one that the amount of data in this research would stay low. Thus, due to the qualitative nature of the data, affinity diagramming was chosen as the
          method for going through the findings. They were created individually from each research phase. The pieces of data were analysed individually and not in relation to the participant to avoid biases and assumptions. Especially, in the final interviews the participants were so familiar that it would have been easy to spoil the data. Separating the transcribed chunks of the interview helped to preserve a neutral perspective. Each study phase was analysed promptly as the results helped to orientate the subsequent phase more towards the research questions.</p>,
        ],
        [
          <MyImg alt="Diary template" src={diary} />
        ]
      ],
    },
    {
      name: "Results",
      dispName: "A Quick Overview of the Results",
      content: [
        [
          <p>This study was initiated by diving deep into the definitions of ethics in design, case studies and the wide selection of ethical design guidelines none of which is considered as a standard. That provided a basis for in-depth interviews with professionals. This research sought to uncover the benefits and downsides of ethical design.</p>,
          <p>Ethics in ethical design carries multiple mutually nonexclusive meanings. I was stated unanimously that ethics is a complex construct that aims to do good while recognising the meaning varies from person to person. Ethics was described as being “relative” and, “multidimensional and branches into many directions”, but also as “strive to do good, and such a pursuit of good is a guiding star”. Moreover,the relativity of ethics is an important thing to keep in mind while working with ethical issues: realisation that what is ethical and responsible for me is not necessarily so for others.</p>,
          <p>There is a mutual understanding that to tackle ethical issues a communal effort is required. It was directly mentioned that active open communication is the key to pondering difficult matters together. The impression was that different people observe the world from different perspectives rendering them to notice different things whereas an individual can only focus on so much.</p>,
          <p>Developing ethical design further starts from the defining work; what it is, what it is not. The benefits of it are direct. Two of the participants mentioned clear definitions makes it easier to discuss with clients, especially if you could highlight the known benefits. Defining “makes it tangible”. Unambiguous definitions that sprout from values within an organisation leads to more cohesive results. Yet in other words, a mutual understanding requires wider perspectives and therefore ensures the communal benefits better.</p>,
          <p>Good design requires awareness; “If there is no awareness nothing can be done better. We would just stay in our own comfort zones.” A similar notion was commonly mentioned in all the interviews although it was sometimes defined as “social bubbles” rather than “comfort zones”. Nonetheless, the quality of design is affected by the designer’s skill in escaping these restrictive zones.</p>,
          <p>This is merely a quick overview of the identified effects of ethical design. If you are interested in the research, I highly recommend reading the thesis in whole. Furthermore, I'd be happy to tell you more about my research.</p>,
        ],
        [
          <p className="quote">Since we have caused the problems, we also have to take responsibility for solving
          them. (--) In a society, responsibility and sustainable development are such broad and
          cutting-edge issues that everyone must embrace and take responsibility.</p>,
          <p className="quote">Measuring only in money is a concept that has outlived its time. Other metrics should be included in decision-making</p>,
          <p className="quote">Being able to share the same priorities and way of doing things is the most important thing that should be promoted in the organisation. (--) Having a common language creates a good baseline for what kind of design we as a community want to do and what we want to focus on.</p>,
        ]
      ],
    },
    {
      name: "Takeaways",
      dispName: "Major Takeaways",
      content: [
        [
          <p>The writing is on the wall; ethical design is an investment. It is still unclear how big the impact will be on the business, organisations, and the daily life of the people. It seems probable that ethical design practices keep evolving and the organisations that invest in it early on will reap the benefits sooner. For an individual designer, this means it is an investment to your professional career development to master this new paradigm. Staying up to date with the design trends, it is not much extra effort to familiarise yourself with this topic. For some, ethical design might prove a useful tool and for others an everyday
          design pattern. But in the end, let the results of this research speak for itself.</p>,
          <p>We still do not understand how computers but, to solve the ethical issues surrounding them, we do not have to because the issues spring from us, not from technology itself. The digital world, in good and bad, is the work of our hands. Are we not responsible for how things are designed? Humans understand humans and ethical questions are always about respecting human rights, effort, and experience. Why is it so difficult to do things right? What is right? Is ethical design the right way to go?</p>,
          <p>This research implicates that ethical design is beneficial, and not only financially. This design paradigm does not only touch designer but developers, managers, users, society, and everyone else affected by the design directly or indirectly; the responsibility is ours, not any individuals. The power of public opinion could be harnessed to demand this change in how things are designed if, that is, we want change.
          </p>
        ],
      ],
    },
  ]
}

export default Thesis;