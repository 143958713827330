import logo from "../../assets/ecoscan/logo.png"
import header from "../../assets/ecoscan/header.png"
import brainstorming from "../../assets/ecoscan/brainstorming.png"
import userStudies from "../../assets/ecoscan/userStudies.png"
import user1 from "../../assets/ecoscan/user1.png"
import user2 from "../../assets/ecoscan/user2.png"
import features from "../../assets/ecoscan/features.png"
import wireframes from "../../assets/ecoscan/wireframes.png"
import screens from "../../assets/ecoscan/screens.png"
import test from "../../assets/ecoscan/test.png"
import ring from "../../assets/ecoscan/ring.png"

import MyImg from "../../components/myImg"

const ecoscan = {
  title: "Case Study: EcoScan",
  subtitle: "Visualising sustainability with AR",
  thumbnailImg: logo, // this shows on home page
  headerImg: header, // this is the big banner image on project page
  link: {
    title: "View Prototype",
    url: "https://www.figma.com/proto/iZgjli1eEur2H4eF6WIKJZ/EcoScan?type=design&node-id=205-2710&t=ZAdYzKjuTq6uG2ic-8&scaling=scale-down&page-id=205%3A1895&starting-point-node-id=205%3A2710&show-proto-sidebar=1&hide-ui=1"
  },
  id: "ecoscan", // simplified name
  overview: {
    challenge: <span><p>In a nutshell, this project is about exploring ways of visualising environmental factors with XR for guiding people towards more sustainable habits. In more practical terms, me and my team welcomed this challenge and designed an app concept that makes grocery shopping easier while promoting sustainability.</p><p>Our solution enhances the grocery shopping experience by calculating the optimal path and showing sustainability-related data in real time. EcoScan is designed to help all customers while awarding for better choices.</p></span>,
    objectives: [
      "Visualise sustainability in a meaningful manner",
      "Deliver a concept that uses AR",
    ],
    scope: "HTI Research and Innovation, Hi-Fi Prototype",
    role: "Project Manager, UX Designer, UX Researcher, Visual Designer",
    team: "Self-directed, with feedback from the teacher",
    tools: "Figma, FigJam, Zoom, MS Office",
    duration: "15 weeks",
  },
  processName: 0, // add a string here to describe the process if not "Design Process"
  sections: [
    { 
      name: "Research",
      dispName: "Research & Ideation",
      content: [
        [
          <MyImg src={brainstorming} alt="Brainstorming mindmap" modal />,
          <span><p>After the usual introductions and setting up project boundaries, we jumped straight into brainstorming and ideation. The graph above shows our starting point. Finding justification for our design decisions, we conducted a literature research by reviewing eight related papers and benchmarked existing solutions. Of which the most  papers were <a href="https://www.sciencedirect.com/science/article/pii/S0040162522001378?casa_token=27hcQIpMyWgAAAAA:_aEYbk36_t1Y_YYQR2-zKvHlKoBN-Jifyvmlo0lLK7GR_ETq3bwxq8-cmijDHeZgM2FmYT_3XA">Nudging more sustainable grocery purchases: Behavioural innovations in a supermarket setting</a> and <a href="https://dl.acm.org/doi/abs/10.1145/3236112.3236161?casa_token=9ym00sjC79AAAAAA:7m1eW0i4AzBO5o9uEceJimv46eU1yRNsSVwBhGCJa3NzQG4jP7OUkDNAvN08Z6JXY2EyDB3TNEsF">PHARA: an augmented reality grocery store assistant</a>. Reviewing these studies gave us ideas for the user studies.</p></span>,
          <MyImg src={userStudies} alt="User studies planning mindmap" modal />,
          <p>Since the previous brainstorming session was fruitful, we used similar approach to designing the user studies. Above, you can see the interplay of FigJam notes forming a somewhat cohesive overview what should be included in the user studies. First, we decided to scope possible participants with an online questionnaire which got 19 responses. Out of those, four expressed their interest to participate in interviews.</p>
        ],
      ],
    },
    {
      name: "Emphatise",
      dispName: "Building User Emphathy",
      content: [
        [
          <p>Based on the all aforementioned research, we built two user personas. The first one, Outi, is a typical university student with a low budget to spend on groceries. The second one, Pekka, is a parent of two kids working as an engineer. While more or less similar, we decided to have two personas as we found having kids has a strong influence on grocery shopping habits.</p>,
          <MyImg src={user1} alt="User persona #1, a 25-year-old university student" modal />,
          <MyImg src={user2} alt="User persona #2, a married engineer, dad of two in his early 30's" modal />,
          <p>It was evident at this point that kids accompanying their parent to the grocery store are secondary users and thus need to be taken into account in the design. These personas were used in the monthly presentations we gave about this project. From here, we proceeded to designing the features and views list.</p>,
        ],
      ],
    },
    {
      name: "Design",
      dispName: "Design & Prototype",
      content: [
        [
          <p>As mentioned, kids affect greatly the sopping experience of parents. The views and features are illustrated in the graph below. We separated "Kids' fun" from the rest by colour coding it. The feature was renamed as "Kids' Mode" and I'll be referring it as such. After a lot of discussions about how we should proceed and taking the scope of our project into account, we decided to drop the Kids' Mode and focus on the core functions of the app. Nonetheless, our task was to illustrate sustainability with AR.</p>,
          <MyImg src={features} alt="Features and views listing as a mindmap" modal />,
          <p>The graph above was quite straightforward to convert into a wireframe as shown below. This wireframe was updated with more finished screens and ultimately created a basis for the hi-fi prototype.</p>,
          <MyImg src={wireframes} alt="First wireframes" modal />,
          <span><p>Our design, EcoScan, is an app that helps any customers. Its main feature is the optimal path visualised in AR. This is illustrated below, the first two screens on the second row. The path is derived from the user's shopping list. When approaching a product, the product is recognised through image recognition and the app shows the sustainability information along the reviews of the product. The user can scan the product with the app and pack it right away. Below illustrated are the screens of the final <a href="https://www.figma.com/proto/iZgjli1eEur2H4eF6WIKJZ/EcoScan?type=design&node-id=205-2710&t=ZAdYzKjuTq6uG2ic-8&scaling=scale-down&page-id=205%3A1895&starting-point-node-id=205%3A2710&show-proto-sidebar=1&hide-ui=1">hi-fi prototype</a> we created. We included the features listed but the visual look is not perfect. More about that in the <a href="#reflection">reflection</a>.</p></span>,
          <p>As you can see, we still included a rough version of the Kids' Mode. This was done as we had some spare time before the deadline and handing out the project. This feature is only a one-direction lo-fi prototype illustrating how EcoScan could be used by a kid accompanying their parent.</p>,
          <MyImg src={screens} alt="Screens of the hi-fi prototype" modal shadowSmall />,
        ],
      ],
    },
    {
      name: "Testing",
      dispName: "Test & Iterate",
      content: [
        [
          <p>Another quick round of interviews were conducted with the participants who participated in the previous interviews as well. We collected the responses to the table below and together discussed what the answers meant. The conclusions were written down on the last row. The next thing would be to take these insights into account and revise the design but our scope ended here.</p>,
          <MyImg src={test} alt="Results of user testing" modal />,
          <span><p>We summarised the most important upcoming features: sustainability points, recipes integration, and kids' mode. First, implementing sustainability points, as an incentive towards sustainable habits, was discussed previously but it seemed to require so much research to implementing it meaningfully that we decided to leave it out for now. The implementation of a such feature would open doors for gamification features. Second, there are already an overabundance of recipe providers so our idea was to utilise existing providers to quickly add the ingredients to the shopping list. Last, <a href="https://parkkamaki.com/work/ecoscan-kids_mode">Kids' Mode</a> seems like an interesting an important addition to the design. It needs to be studied how we could design a system that kids would enjoy and make the shopping experience for the parent more pleasant. These are features we didn't implement and left for future iterations of EcoScan.</p></span>
        ],
      ],
    },
    {
      name: "Reflection",
      dispName: "Lessons Learned",
      content: [
        [
          <p>Thorough literature review in the beginning is worth the effort although it might feel like a burden - I'm guilty of procrastinating on that too much. During this project, I tried to find reasons for why I have this behaviour and came to the conclusion I'm personally more practice-oriented as a designer; I strive more in brainstorming and testing different solutions.</p>,
          <p>Do not try to do one-app-to-rule-them-all. Since our team was full of ideas, it was difficult for us all to decide which ones to focus on. I personally learned that it's better to have too much ideas initially and chip away until the idea fits the scope. It's good to prioritise features and create the MVP design first.</p>,
          <p>Scarcity of tools is a virtue; having everything on a single FigJam file makes it difficult to forget or ignore insights and what has previously been discussed. Moreover, a visual schedule helps understand the schedule better. Literally seeing the deadline getting closer gives a good perspective on how much time is left.</p>,
          <p>Perfectionism needs to be put aside sometimes. The focus of this project was not visual appeal but, nevertheless, I got frustrated when others didn't follow the visual style I created early on. I had to choose whether to fix it or leave it. I chose the latter although it felt uncomfortable at first. However, not following the visual guide or best practices on prototyping with Figma, it makes it more difficult to continue the project.</p>,
        ],
        [
          <MyImg src={ring} alt="A golden ring referencing to the ring of power from Lords of the Rings" />
        ],
      ],
    },
  ]
}

export default ecoscan;