import { Link } from "react-router-dom"
import projects from "../projects/projects"
import { Container } from "react-bootstrap"

const Work = () => {
  return (
    <Container>
      <Container className="pt-md-5 pt-3 pb-md-3 pb-1">
        <div className="d-inline-block col-12">
          <h1 className="pt-4">My Previous Projects</h1>
          {/* <h3 className="py-sm-4 py-2">Future Ethical Designer</h3> */}
          <div className="py-sm-4 py-2 subtitle">On this page, you'll find the projects I included in this portfolio as a list.</div>
        </div>
      </Container>

      <Container fluid className="py-md-5 py-3">
        <Container>
          <h2 className='pt-md-0 pt-4'>Work</h2>
          <ul>
            {projects.map(w => <li key={w.id}><Link to={`/work/${w.id}`} >{w.title}</Link></li>)}
          </ul>
        </Container>
      </Container>
    </Container>
  )
}

export default Work