import { Button } from "react-bootstrap";

const closeModal = () => document.getElementById("modal").style.display = "none";

const openModal = (src, alt) => {
  document.getElementById("modal-img").src = src;
  document.getElementById("modal-img").alt = alt;
  document.getElementById("modal-caption").innerHTML = alt;
  document.getElementById("modal").style.display = "block";
}

const ModalImage = () => {
  
  const dontCloseModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  return (
    <div id="modal" className="modal" onClick={closeModal}>
      <Button variant='dark' className='proto-button modal-button'>&times;</Button>
      <img className="modal-content p-2" alt="placeholder" onClick={(e) => dontCloseModal(e)} id="modal-img" />
      <div className="modal-caption" id="modal-caption"></div>
    </div>
  );
}

export { ModalImage, openModal, closeModal };