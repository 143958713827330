import { Outlet, Link } from "react-router-dom";
// import AppBar from "../components/appBar";
import { Container, Nav, Navbar, Image } from 'react-bootstrap/';
import Footer from "../components/footer";

import logo from '../assets/logo.svg'
import { Fragment } from "react";

const Layout = () => {
  return (
    <Fragment>
      {/* <AppBar /> */}
      <Navbar className="gray-bg" id="top" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image className="d-inline-block" src={logo} style={{width: "3ch"}} />
            <div className="name d-inline-block px-sm-4 px-2">Lauri Parkkamäki</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Navbar.Text>
              <Nav className="me-auto">
                <Nav.Link className="ps-4 caps" as={Link} to="/">Home</Nav.Link>
                {/* <Nav.Link className="px-2 caps" as={Link} to="work">Work</Nav.Link> */}
                <Nav.Link className="ps-4 caps" as={Link} to="contact">Contact</Nav.Link>
                <Nav.Link className="ps-4 caps" href="https://prkmk.medium.com/" target="_blank">Blog ↗</Nav.Link>
                <Nav.Link className="ps-4 caps" href="https://littlebitspace.com" target="_blank">Art ↗</Nav.Link>

              </Nav>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />

      <Footer />
    </Fragment>
  )
}

export default Layout