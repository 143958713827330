import { Fragment, useEffect, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import projects from '../projects/projects'
import { ModalImage } from '../components/modalImage'
import E404 from "../routes/e404"

const Project = () => {
  const [isMd, setIsMd] = useState(window.screen.width >= 768);

  const handleResize = () => setIsMd(window.screen.width >= 768);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const { projectId } = useParams()
  const p = projects.find(w => w.id === projectId)

  var i = Math.random;
  const getUniqueKey = () => {i += 1; return i};


  if (typeof p == 'undefined') return(<E404 />);

  return(
    <Fragment>
      <Container className="py-md-5 py-sm-3 px-4">
          <div className="d-inline-block text-center">
            {p.title && <h1 className='pt-5'>{p.title}</h1>}
            {p.subtitle && <div className='project-subtitle pt-2 pb-md-5 pb-3'>{p.subtitle}</div>}
            {p.headerImg && <Image src={p.headerImg} className='img-shadow py-md-5 py-0' style={{ width: "100%" }} />}
            {p.link && <Button href={p.link.url} target="_blank" variant='dark' className='proto-button my-4'>{p.link.title ? p.link.title : "View Prototype"}</Button> }
          </div>
      </Container>
      {/* OVERVIEW */}
      <Container fluid className="py-5 gray-bg px-4">
        <Container> 
          <h2 className='py-2 pb-5 text-center'>{p.overview.title ? p.overview.title : "Project Overview"}</h2>
          <div className="d-inline-block col-lg-7 col-md-7 col-sm-12">
            <h5 className='bold'>Challenge</h5>
            {p.overview.challenge}
            <h5 className='bold'>{p.overview.objectivesTitle ? p.overview.objectivesTitle : "Objectives"}</h5>
            <ul>{p.overview.objectives.map(o => <li key={o}>{o}</li>)}</ul>
          </div>
          <div className="d-inline-block col-lg-1 col-md-1 col-sm-0"></div>
          <div className="pb-2 d-inline-block col-lg-4 col-md-4 col-sm-12 align-top">
            {p.overview.scope && <Fragment>
              <h6 className='bold'>Project Scope</h6>
              <p>{p.overview.scope}</p>
              </Fragment>
            }
            {p.overview.role && <Fragment>
              <h6 className='bold'>Role</h6>
              <p>{p.overview.role}</p>
              </Fragment>
            }
            {p.overview.team && <Fragment>
              <h6 className='bold'>Team</h6>
              <p>{p.overview.team}</p>
              </Fragment>
            }
            {p.overview.tools && <Fragment>
              <h6 className='bold'>Tools</h6>
              <p>{p.overview.tools}</p>
              </Fragment>
            }
            {p.overview.duration && <Fragment>
              <h6 className='bold'>Duration</h6>
              <p>{p.overview.duration}</p>
              </Fragment>
            }
          </div>
        </Container>
      </Container>
      <Container fluid className="py-lg-5 py-md-5 py-sm-3 px-4">
        {/* PROJECT NAV */}
        {/* <Container className='project-nav d-xl-block d-lg-none d-md-none d-sm-none'> */}
        <Container className='project-nav d-md-block d-none'>
          {p.sections && p.sections.map(s => <a key={s.name} className='mx-3' href={`#${s.name.toLowerCase()}`}>{s.name}</a>)}
        </Container>
        {/* CONTENTS */}
        <Container>
          <h2 className='text-center pt-md-0 pt-4'>
            {p.processName ? p.processName : "Design Process"}
          </h2>
          {p.sections && p.sections.map((s, si) => 
            <div key={s.name} id={s.name.toLowerCase()} className='pt-md-5 pt-3 pb-md-5 pb-3'>
              <h3 className='pb-md-4 pb-2 text-center'>{s.dispName}</h3>
              {/* {s.content.map((c, i) => <Fragment key={i}>{c}</Fragment>)} */}
              {s.content.length <= 1 ?
                s.content[0].map(c => <Fragment key={getUniqueKey()}>{<div className='pb-3'>{c}</div>}</Fragment>)
                : <Fragment>
                  {(!isMd || si % 2 === 1) && <div className='pe-3 align-top d-inline-block col-md-4'>{s.content[1].map(c => <Fragment key={getUniqueKey()}>{c}</Fragment>)}</div>}
                  
                  <div className={`p${si % 2 === 1 ? "s" : "e"}-3 align-top d-inline-block col-md-8`}>{s.content[0].map(c => <Fragment key={getUniqueKey()}>{c}</Fragment>)}</div>

                  {(isMd && si % 2 === 0) && <div className='ps-3 align-top d-inline-block col-md-4'>{s.content[1].map(c => <Fragment key={getUniqueKey()}>{c}</Fragment>)}</div>}
                </Fragment>
              }
            </div>
            )}
        </Container>
      </Container>
      <ModalImage />
    </Fragment>
  )
}

export default Project