import fugenjikko from "./projects/fugenjikko"
import mastersThesis from "./projects/mastersThesis"
import ecoscan from "./projects/ecoscan";
import flow from "./projects/flow";
// import ecoscanKidsMode from "./projects/ecoscanKidsMode";

const projects = [
  flow,
  mastersThesis,
  // ecoscanKidsMode,
  ecoscan,
  fugenjikko,
];

export default projects;