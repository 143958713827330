import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Footer = () => {
  return(
    <Navbar
      className='gray-bg'
      variant="light"
      expand="lg"
    >
      <Container className='px-4 justify-content'>
        <Nav.Link as={Link} to="/">© Lauri Parkkamäki '{new Date().getFullYear().toString().slice(2)}</Nav.Link>
        <Nav.Link href="#top">Back to top</Nav.Link>
      </Container>
    </Navbar>
  )
}

export default Footer;