import { Container, Button } from 'react-bootstrap'
import cat from "../assets/cat.txt"
import { useEffect, useState } from 'react'

const E404 = () => {
  const [ascii, setAscii] = useState([]);

  useEffect(() => {
    fetch(cat)
      .then(r => r.text())
      .then(text => setAscii(text));
  }, []);

  return (
    <Container className="e404 py-md-5 py-sm-3 px-4">
      <h1>E404</h1>
      <div className='subtitle pt-2 pb-1'>Page not found. Maybe the cat has pushed what you were looking for off the table.</div>
      <Button onClick={() => window.history.back()} variant='dark' className='proto-button mt-3 mb-md-5 mb-3'>Go back</Button>
      <pre className='text-center'>
        {ascii}
      </pre>
    </Container>
  )
}

export default E404