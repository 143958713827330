import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import Layout from './routes/layout';
import Home from "./routes/home";
import Work from "./routes/work";
import E404 from './routes/e404';
import Project from './routes/project';
import Contact from './routes/contact';

const App = () => {
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/work">
            <Route index element={<Work />} />
            <Route path=":projectId" element={<Project />} />
          </Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<E404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <App /> );
