import { Container, Image } from "react-bootstrap"
import ProfilePic from "../assets/prkmk.jpg"

import WorkCard from "../components/workCard"

import projects from "../projects/projects"

const Home = () => {
  return (
    <Container>
      <Container className="center-vertically py-md-5 py-3">
          <div className="d-inline-block col-md-8 col-sm-12 pe-md-3 pe-0">
            <h1 className="pt-4">Lauri Parkkamäki</h1>
            <h3 className="py-sm-4 py-2">Future Ethical Designer</h3>
            <div className="subtitle">I’m a UX designer with a burning passion for sustainability. I design ethical solutions that will nudge people and organisations towards better tomorrow.</div>
          </div>
          <div className="d-inline-block col-md-4 col-sm-12">
            <Image className="ps-md-3 px-3 col-12" src={ProfilePic} />
          </div>
      </Container>

      <Container fluid className="py-md-5 py-3">
        <h2 className='pb-md-5 py-3'>My Projects</h2>
        <Container>
          {projects.map(w => 
            <WorkCard key={w.title} title={w.title} src={w.thumbnailImg} id={w.id} />  
          )}
        </Container>
      </Container>
    </Container>
  )
}

export default Home